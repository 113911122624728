
.cke_chrome {
  box-shadow: 0 0 17px 0 rgba(0,0,0,0.29);
}

.admin-options {
  .option-name {
    font-weight: 600;
  }
  .row {
    padding: 0.5em;
    margin-bottom: 10px;
  }
  .row:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.08);
  }
}
